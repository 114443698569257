const McpNew = () => import('@/views/ContentDdTool/McpNew')
const McpSecondary = () => import('@/views/ContentDdTool/McpSecondary')
const McpUsed = () => import('@/views/ContentDdTool/McpUsed')
const Mob = () => import('@/views/ContentDdTool/Mob')
const McpUsedOffBrand = () => import('@/views/ContentDdTool/McpUsedOffBrand')
const McpMobPage = () => import('@/views/ContentDdTool/McpMobPage')
const ProDrive = () => import('@/views/ContentDdTool/ProDrive')
const ProDrivePage = () => import('@/views/ContentDdTool/ProDrivePage')
const McpTertiary = () => import('@/views/ContentDdTool/McpTertiary')
const FixedOps = () => import('@/views/ContentDdTool/FixedOps')
const ClientContents = () => import('@/views/ContentDdTool/ClientContents')
const SecondaryFixedOps = () => import('@/views/ContentDdTool/SecondaryFixedOps')
const TertiaryFixedOps = () => import('@/views/ContentDdTool/TertiaryFixedOps')
const QuaternaryFixedOps = () => import('@/views/ContentDdTool/QuaternaryFixedOps')
const QuinaryFixedOps = () => import('@/views/ContentDdTool/QuinaryFixedOps')
const SenaryFixedOps = () => import('@/views/ContentDdTool/SenaryFixedOps')
const SeptenaryFixedOps = () => import('@/views/ContentDdTool/SeptenaryFixedOps')
const FixedOpsPage = () => import('@/views/ContentDdTool/components/FixedOpsPage')
const ClientContentPage = () => import('@/views/ContentDdTool/components/ClientContentPage')
const ClientPlannedContents = () => import('@/views/ContentDdTool/ClientPlannedContents')
const McpQuaternary = () => import('@/views/ContentDdTool/McpQuaternary')
const McpQuinary = () => import('@/views/ContentDdTool/McpQuinary')
const McpSenary = () => import('@/views/ContentDdTool/McpSenary')
const McpSeptenary = () => import('@/views/ContentDdTool/McpSeptenary')

const contentDdToolRoutes = [
  {
    path: '/content-dd/mcp',
    name: 'content.new-mcp',
    component: McpNew,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/secondary',
    name: 'content.secondary-mcp',
    component: McpSecondary,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/used',
    name: 'content.used-mcp',
    component: McpUsed,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/used-mcp-off-brand',
    name: 'content.used-mcp-off-brand',
    component: McpUsedOffBrand,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/mob',
    name: 'content.mob',
    component: Mob,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/clients/:client_id/brands/:brand_id/model-year/:model_year_id/:page_type',
    name: 'content.mcp-mob-page',
    component: McpMobPage,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/pro-drive',
    name: 'content.pro-drive',
    component: ProDrive,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/clients/:client_id/pro-drive/:page_id',
    name: 'content.pro-drive-detail',
    component: ProDrivePage,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/tertiary',
    name: 'content.tertiary-mcp',
    component: McpTertiary,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/quaternary',
    name: 'content.quaternary-mcp',
    component: McpQuaternary,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/5th-geo-mcp',
    name: 'content.5th-geo-mcp',
    component: McpQuinary,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/6th-geo-mcp',
    name: 'content.6th-geo-mcp',
    component: McpSenary,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/7th-geo-mcp',
    name: 'content.7th-geo-mcp',
    component: McpSeptenary,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/clients-contents-plan',
    name: 'content.clients-contents',
    component: ClientPlannedContents,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/fixed-ops',
    name: 'content.fixed-ops',
    component: FixedOps,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/client-contents',
    name: 'content.client-contents',
    component: ClientContents,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/secondary-fixed-ops',
    name: 'content.secondary-fixed-ops',
    component: SecondaryFixedOps,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/tertiary-fixed-ops',
    name: 'content.tertiary-fixed-ops',
    component: TertiaryFixedOps,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/quaternary-fixed-ops',
    name: 'content.quaternary-fixed-ops',
    component: QuaternaryFixedOps,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/5th-geo-fixed-ops',
    name: 'content.5th-geo-fixed-ops',
    component: QuinaryFixedOps,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/6th-geo-fixed-ops',
    name: 'content.6th-geo-fixed-ops',
    component: SenaryFixedOps,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/content-dd/7th-geo-fixed-ops',
    name: 'content.7th-geo-fixed-ops',
    component: SeptenaryFixedOps,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/clients/:client_id/brands/:brand_id/:fixed_ops_id/:page_type',
    name: 'content.fixed-ops-page',
    component: FixedOpsPage,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  },
  {
    path: '/client-contents/:client_id/brands/:brand_id/:content_page_id/:page_type',
    name: 'content.client-contents-page',
    component: ClientContentPage,
    meta: {
      requiresAuth: true,
      module: 'content-dd-tool'
    }
  }
]

export default contentDdToolRoutes
